// import React, { useEffect, useState } from "react";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import { SettingNode } from "../../../models/monitor/MonitorDetailResponse.model";
// import { Socket } from "socket.io-client";

// interface chartProps {
//   datachart: any;
//   setting_node_data_name: string;
//   height: number | string;
//   graph_color?: string;
// }

// // const handleArr = (setting: SettingNode, data: string) => {
// //     if (data != "0") {
// //       if (setting.setting_node_data_type == "1") { // 1 = Array
// //         const dataArray = data.split(',');
// //         const index = parseInt(setting.setting_node_sequence);
// //         if (index >= 0 && index < dataArray.length) {
// //           const inputValue = parseFloat(dataArray[index]);
// //           if (!isNaN(inputValue)) {
// //             return inputValue.toFixed(2);
// //           }
// //           return dataArray[index];
// //         } else {
// //           return "0";
// //         }
// //       } else {
// //         return data;
// //       }
// //     }
// //     return "0";
// // };

// // let datachart: Array<{ name: string, [key: string]: string }> = []; // Array สำหรับเก็บข้อมูลล่าสุด

// const LineChartComponent: React.FC<chartProps> = ({
//   datachart,
//   setting_node_data_name,
//   height = 200,
//   graph_color = "#00b0f0",
// }) => {
//   // สร้าง datachart จาก socketData และ settingNodes

//   return (
//     <ResponsiveContainer width="100%" height={height}>
//       <LineChart
//         width={500}
//         height={300}
//         data={datachart} // ใช้ datachart ที่ได้จาก createDataChart
//         margin={{
//           top: 5,
//           right: 30,
//           left: 0,
//           bottom: 5,
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         <XAxis dataKey="name" />
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         {/* ใช้ setting_node_data_name เป็น dataKey ของ Line */}
//         <Line
//           type="monotone"
//           dataKey={setting_node_data_name} // ดึงชื่อจาก settingNodes
//           stroke={graph_color}
//           strokeWidth={2}
//           dot={{ r: 4 }}
//         />
//       </LineChart>
//     </ResponsiveContainer>
//   );
// };

// export default LineChartComponent;

import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { SettingNode } from "../../../models/monitor/MonitorDetailResponse.model";
import { Socket } from "socket.io-client";

interface chartProps {
  socketData: any;
  settingNodes: any;
  setting_node_data_name: string;
  height: number | string;
  graph_color?: string;
  initialData: any;
}

// let datachart: Array<{ name: string, [key: string]: string }> = []; // Array สำหรับเก็บข้อมูลล่าสุด
const handleArr = (setting: SettingNode, data: string) => {
  if (data != "0") {
    if (setting.setting_node_data_type == "1") {
      // 1 = Array
      const dataArray = data.split(",");
      const index = parseInt(setting.setting_node_sequence);
      if (index >= 0 && index < dataArray.length) {
        const inputValue = parseFloat(dataArray[index]);
        if (!isNaN(inputValue)) {
          return inputValue.toFixed(2);
        }
        return dataArray[index];
      } else {
        return "0";
      }
    } else {
      return data;
    }
  }
  return "0";
};
const LineChartComponent: React.FC<chartProps> = ({
  socketData,
  settingNodes,
  setting_node_data_name,
  height = 200,
  graph_color = "#00b0f0",
  initialData,
}) => {
  // สร้าง datachart จาก socketData และ settingNodes
  const [datachart, setDatachart] = useState<
    Array<{ name: string; [key: string]: string }>
  >([]);
  const createDataChart = (
    setting: SettingNode,
    data: any,
    prevDataChart: Array<{ name: string; [key: string]: string }>
  ) => {
    const sensorData =
      data[setting.floodgate_id]?.[setting.setting_node_data_key];
    const transactionDate =
      data[setting.floodgate_id]?.floodgate_transaction_createdate;

    if (!sensorData || !transactionDate) {
      return prevDataChart;
    }

    // Format time from transactionDate (HH:mm)
    const transactionTime = new Date(transactionDate).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Handle the array data and format the value for chart
    const sensorValue = handleArr(setting, sensorData);

    // Add the new data into datachart
    const updatedData = [
      ...prevDataChart,
      {
        name: transactionTime,
        [setting.setting_node_data_name]: sensorValue,
      },
    ];
    if (updatedData.length > 10) {
      updatedData.shift(); // Remove the oldest entry
    }

    return updatedData;
  };

  useEffect(() => {
    initialData?.forEach((data: any) => {
      setDatachart((prevDataChart) =>
        createDataChart(settingNodes!, data, prevDataChart)
      );
    });
  }, []);

  useEffect(() => {
    setDatachart((prevDataChart) =>
      createDataChart(settingNodes!, socketData, prevDataChart)
    );
  }, [settingNodes, socketData]);

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        width={500}
        height={300}
        data={datachart} // ใช้ datachart ที่ได้จาก createDataChart
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* ใช้ setting_node_data_name เป็น dataKey ของ Line */}
        <Line
          type="monotone"
          dataKey={setting_node_data_name} // ดึงชื่อจาก settingNodes
          stroke={graph_color}
          strokeWidth={2}
          dot={{ r: 4 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
