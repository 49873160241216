import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Modal,
  Paper,
  styled,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LineChartComponent from "../../../layouts/Charts/LineChartComponent";
// import { SettingNode } from '../../../models/monitor/MonitorDetailResponse.model';
import { SettingNode } from "../../../../models/monitor/MonitorDetail.model";
import {
  controlFloodgate,
  controlFloodgateSelector,
} from "../../../../stores/slices/ControlFloodgateSlice";
import { useAppDispatch, useAppSelector } from "../../../../stores/store";
import Swal from "sweetalert2";
interface CardImageProps {
  path?: string;
  imagename?: string;
  settingNodes?: SettingNode[];
  socketData: { [key: string]: { [key: string]: string } };
  initialData: Array<{
    [key: string]: {
      WaterLevel: string;
      floodgate_transaction_createdate: string;
    };
  }> | null;
}

const handleArr = (setting: SettingNode, data: string) => {
  if (data != "0") {
    if (setting.setting_node_data_type == "1") {
      // 1 = Array
      const dataArray = data.split(",");
      const index = parseInt(setting.setting_node_sequence);
      if (index >= 0 && index < dataArray.length) {
        const inputValue = parseFloat(dataArray[index]);
        if (!isNaN(inputValue)) {
          return inputValue.toFixed(2);
        }
        return dataArray[index];
      } else {
        return "0";
      }
    } else {
      return data;
    }
  }
  return "0";
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  borderRadius: "12px",
  backgroundColor: "rgba(200, 255, 255, 0.2)",
  boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
  marginBottom: 10,
}));

const CircleComponent = ({ color = "black", headColor = "yellow" }) => {
  return (
    <Box
      sx={{
        width: 20,
        height: 20,
        backgroundColor: `${headColor}`,
        border: `1px solid ${color}`,
        borderRadius: "50%",
        flexShrink: 0,
      }}
    />
  );
};

const NodeItem: React.FC<CardImageProps> = ({
  path = "FlapGateWeirCurved1",
  imagename = "0",
  settingNodes,
  socketData,
  initialData,
}) => {
  const dispatch = useAppDispatch();

  const { data, isLoading } = useAppSelector(controlFloodgateSelector);
  const floodgateId = settingNodes ? settingNodes[0].floodgate_id : "0";
  const checkImageExists = (imagepath: string) => {
    fetch(imagepath, { method: "HEAD" })
      .then((response) => {
        if (response.ok) {
          return imagepath;
        } else {
          return `${process.env.PUBLIC_URL}/images/no-photo-available.png`;
        }
      })
      .catch((error) => {
        return `${process.env.PUBLIC_URL}/images/no-photo-available.png`;
      });
    return `${process.env.PUBLIC_URL}/images/no-photo-available.png`;
  };

  const light_color = "#888888";
  // สร้าง state สำหรับ modal

  // const [control, setControl] = useState<string>("");

  const isTextSubmit = async (
    param: string,
    btn_name?: string,
    setting_node_id?: string,
    node?: any
  ) => {
    if (
      node?.setting_node_data_type === 0 ||
      node?.setting_node_data_type === 1
    ) {
      const value = parseFloat(param);
      if (!isNaN(value) && isFinite(value)) {
        if (
          !(
            value >= parseFloat(node?.setting_node_data_min) &&
            value <= parseFloat(node?.setting_node_data_max)
          )
        ) {
          Swal.fire(
            "เกิดข้อผิดพลาด",
            `ข้อมูลควรอยู่ระหว่าง ${node?.setting_node_data_min}  ถึง ${node?.setting_node_data_max}`,
            "error"
          );
        } else {
          if (floodgateId && setting_node_id && param) {
            const result = await Swal.fire({
              title: "คุณแน่ใจหรือไม่?",
              text: `คุณจะดำเนินการ ${btn_name} ใช่หรือไม่`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "ใช่, ดำเนินการ!",
              cancelButtonText: "ไม่, ยกเลิก",
            });

            if (result.isConfirmed) {
              try {
                const loadingSwal = Swal.fire({
                  title: "กำลังดำเนินการ...",
                  html: `
                  <div style="display: flex; flex-direction: column; align-items: center;">
                    <div class="swal2-loader" style="margin-bottom: 20px;"></div>
                  </div>
                  <div style="text-align: center; margin-top: 20px;">
                    <button id="cancelButton" class="swal2-cancel swal2-styled">ยกเลิก</button>
                  </div>
                `,
                  didOpen: () => {
                    Swal.showLoading();

                    // กำหนดให้ปุ่มยกเลิกทำงาน
                    const cancelButton =
                      document.getElementById("cancelButton");
                    if (cancelButton) {
                      cancelButton.addEventListener("click", () => {
                        Swal.close();
                        Swal.fire("ยกเลิกการดำเนินการ", "", "info");
                      });
                    }
                  },
                  showConfirmButton: false, // ไม่แสดงปุ่ม Confirm เพราะใช้ปุ่มยกเลิกแทน
                  allowOutsideClick: false,
                });
                await dispatch(
                  controlFloodgate({
                    floodgate_id: floodgateId,
                    setting_node_id: setting_node_id,
                    value: param,
                  })
                );
                if (data?.data.response && data.data.response.floodgate_data) {
                  const responseString = Object.entries(
                    data.data.response.floodgate_data
                  )
                    .map(([key, value]) => `${key}: ${value}`)
                    .join(", ");
                  Swal.fire(
                    "ดำเนินการเรียบร้อย.",
                    `${responseString}`,
                    "success"
                  );
                } else {
                  Swal.fire(
                    "เกิดข้อผิดพลาด",
                    "ไม่มีการตอบกลับจาก Server ปลายทาง",
                    "error"
                  );
                }
                // Swal.fire('ดำเนินการเรียบร้อย.', `${data?.data.response.floodgate_data}`, 'success');
              } catch (error: any) {
                Swal.fire("ดำเนินการไม่สำเร็จ", error.message, "error");
              }
            }
          } else {
            console.error("Missing data to control floodgate");
          }
        }
      } else {
        Swal.fire("เกิดข้อผิดพลาด", "ชนิดข้อมูลไม่ถูกต้อง", "error");
      }
    }
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  // const [gridState, setGridState] = useState(12);

  const getGridState = (display: any) => {
    try {
      if (Array.isArray(display)) {
        return (display[0].is_monitor &&
          display[0].is_status_light &&
          display.length == 1) ||
          (display[0].is_control && display[0].is_btn && display.length == 1)
          ? 6
          : 12;
      }
      return 12;
    } catch (error) {
      return 12;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          // backgroundColor: '#FFF',
          borderRadius: "0.6rem",
        }}
      >
        <Grid container spacing={2}>
          {settingNodes?.map((node, index) => (
            <Grid
              item
              xs={12}
              sm={getGridState(node.nodetype_input_and_display)}
              md={getGridState(node.nodetype_input_and_display)}
              lg={getGridState(node.nodetype_input_and_display)}
              sx={{
                borderRadius: "0.6rem",
                marginBottom: 2,
              }}
            >
              {node.nodetype_input_and_display.length > 1 ? (
                <Card
                  className="shadow-lg rounded-lg"
                  key={`control-${index}`}
                  sx={{
                    textAlign: "center",
                    borderRadius: "12px",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    boxShadow: "none",
                  }}
                >
                  <div className="bg-gray-100 p-4 border-b border-gray-200">
                    <Typography variant="h6" component="div">
                      {node.setting_node_data_name}
                    </Typography>
                  </div>

                  <CardContent>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      {node.nodetype_input_and_display.length != 0 && node.nodetype_input_and_display.map((display) => {
                        if (display.is_control) {
                          const [control, setControl] = useState<string>("");
                          const handleChange = (event: any) => {
                            setControl(event.target.value);
                          };
                          return (
                            <Grid>
                              {display.is_btn ? (
                                <Button
                                  key={index}
                                  onClick={() =>
                                    isTextSubmit(
                                      display.btn_value,
                                      display.btn_name,
                                      node.setting_node_id,
                                      node
                                    )
                                  }
                                  variant="contained"
                                  style={{
                                    backgroundColor: display.btn_color,
                                    marginRight: 10,
                                    marginBottom: 10,
                                  }}
                                  className="bg-blue-500"
                                >
                                  {display.btn_name}
                                </Button>
                              ) : display.is_text_input && index === 0 ? (
                                <Box
                                  key={`input-${index}`}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  gap={2}
                                  sx={{ width: "100%" }}
                                >
                                  <TextField
                                    fullWidth
                                    label={display.text_input_name}
                                    value={control}
                                    onChange={handleChange}
                                    sx={{ flexGrow: 1 }}
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="bg-blue-500"
                                    sx={{
                                      width: "auto",
                                      height: "55px",
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() =>
                                      isTextSubmit(
                                        control,
                                        display.text_input_name,
                                        node?.setting_node_id,
                                        node
                                      )
                                    }
                                  >
                                    ดำเนินการ
                                  </Button>
                                </Box>
                              ) : null}
                            </Grid>
                          );
                        }

                        if (display.is_monitor && (display.is_img || display.is_graph)) {
                          const imagepath = checkImageExists(
                            `/ProjectImages/${node?.nodetype_input_and_display.map(
                              (item) => item.img_group_name
                            )}/${parseInt(handleArr(
                              node!,
                              socketData[floodgateId]?.[
                              node?.setting_node_data_key || ""
                              ] || "0"
                            ))}.png`
                          );

                          const [open, setOpen] = useState(false);
                          const handleOpen = () => setOpen(true);
                          const handleClose = () => setOpen(false);
                          return (
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  // backgroundColor: '#FFF',
                                  borderRadius: "0.6rem",
                                }}>
                                <>
                                  {
                                    <StyledPaper>
                                      <Typography
                                        variant="h6"
                                        textAlign="left"
                                        gutterBottom
                                      >
                                        {node?.setting_node_data_name}
                                      </Typography>
                                      <Typography
                                        variant="h3"
                                        textAlign="center"
                                        gutterBottom
                                      >
                                        {handleArr(
                                          node!,
                                          socketData[floodgateId]?.[
                                          node?.setting_node_data_key || ""
                                          ] || "0"
                                        )}
                                      </Typography>
                                      <Typography
                                        variant="h5"
                                        textAlign="center"
                                        gutterBottom
                                      >
                                        {node?.setting_node_data_unit}
                                      </Typography>
                                    </StyledPaper>
                                  }
                                  {display.is_img && (
                                    <StyledPaper>
                                      <Typography
                                        variant="h6"
                                        textAlign="left"
                                        gutterBottom
                                      >
                                        รูปภาพกราฟฟิก :{" "}
                                        {node?.setting_node_data_name}
                                      </Typography>
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                      >
                                        <Box
                                          component="img"
                                          src={imagepath}
                                          alt="Image"
                                          sx={{
                                            width: "50%",
                                            height: "auto",
                                            borderRadius: "8px",
                                            boxShadow: 3,
                                          }}
                                        />
                                      </Box>
                                    </StyledPaper>
                                  )}
                                  {display.is_graph && (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        sx={{
                                          // backgroundColor: '#FFF',
                                          borderRadius: "0.6rem",
                                        }}
                                      >
                                        <StyledPaper onClick={handleOpen}>
                                          <Typography
                                            variant="h6"
                                            textAlign="left"
                                            gutterBottom
                                          >
                                            กราฟแสดงค่า :{" "}
                                            {node?.setting_node_data_name}
                                          </Typography>
                                          <LineChartComponent
                                            socketData={socketData}
                                            settingNodes={node}
                                            setting_node_data_name={
                                              node.setting_node_data_name
                                            }
                                            height={200}
                                            graph_color={display.graph_color}
                                            initialData={initialData}
                                          />
                                        </StyledPaper>
                                      </Grid>
                                      <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-title"
                                        aria-describedby="modal-description"
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            width: {
                                              xs: "90%", // หน้าจอเล็กสุด (มือถือ) ให้ใช้เต็มความกว้าง
                                              sm: "90%", // หน้าจอ sm (เล็ก) ให้ใช้ 90%
                                              md: "80%", // หน้าจอขนาดกลางให้ใช้ 80%
                                              lg: "70%", // หน้าจอขนาดใหญ่ให้ใช้ 70%
                                            },
                                            height: {
                                              xs: "60%", // หน้าจอ xs (มือถือ) ให้สูง 80%
                                              sm: "70%", // หน้าจอ sm ให้สูง 70%
                                              md: "90%", // หน้าจอขนาดกลางให้สูง 90%
                                              lg: "90%", // หน้าจอขนาดใหญ่ให้สูง 90%
                                            },
                                            backgroundColor: "white",
                                            borderRadius: "10px",
                                            boxShadow: 24,
                                            padding: 4,
                                            position: "relative",
                                            display: "flex", // ใช้ flexbox เพื่อจัด layout ภายใน
                                            flexDirection: "column", // จัดเรียง items แนวตั้ง
                                            overflow: "hidden", // ซ่อนเนื้อหาที่ล้น
                                          }}
                                        >
                                          <Typography
                                            id="modal-title"
                                            variant={isMobile ? "h6" : "h4"}
                                            gutterBottom
                                          >
                                            กราฟแสดงค่า :{" "}
                                            {node?.setting_node_data_name}
                                          </Typography>

                                          <Box
                                            sx={{
                                              flexGrow: 1, // ให้ LineChartComponent เติบโตเต็มที่ที่เหลืออยู่
                                              overflow: "auto", // ทำให้ Chart เลื่อนถ้าเกินขนาด
                                            }}
                                          >
                                            <LineChartComponent
                                              socketData={socketData}
                                              settingNodes={node}
                                              setting_node_data_name={
                                                node.setting_node_data_name
                                              }
                                              height={isMobile ? 200 : "100%"}
                                              graph_color={
                                                display.graph_color
                                              }
                                              initialData={initialData}
                                            />
                                          </Box>

                                          {/* ปุ่มปิด อยู่ที่มุมขวาบน */}
                                          <Button
                                            onClick={handleClose}
                                            variant="contained"
                                            sx={{
                                              position: "absolute",
                                              top: 16,
                                              right: 16,
                                            }}
                                          >
                                            ปิด
                                          </Button>
                                        </Box>
                                      </Modal>
                                    </>
                                  )}
                                </>
                              </Grid>
                            </Grid>
                          );
                        }

                        if (display.is_monitor && display.is_status_light) {
                          return (
                            <>
                              {display.is_status_light && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                  sx={{
                                    borderRadius: "0.6rem",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    sx={{
                                      backgroundColor: "#FFF",
                                      borderRadius: "0.6rem",
                                    }}
                                  >
                                    <Box
                                      display="flex"
                                      flexWrap="wrap"
                                      alignItems="center"
                                      justifyContent="flex-start"
                                    >
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        marginRight={2}
                                        marginBottom={2}
                                      >
                                        <CircleComponent
                                          color="black"
                                          headColor={
                                            (node.setting_node_data_type == "1" || node.setting_node_data_type != "0") ?
                                              (parseFloat(display.status_light_value) ===
                                                parseFloat(handleArr(
                                                  node!,
                                                  socketData[floodgateId]?.[
                                                  node?.setting_node_data_key || ""
                                                  ] || "0"))
                                                ? display.status_light_color
                                                : light_color)
                                              : (display.status_light_value ===
                                                handleArr(
                                                  node!,
                                                  socketData[floodgateId]?.[
                                                  node?.setting_node_data_key || ""
                                                  ] || "0")
                                                ? display.status_light_color
                                                : light_color)
                                          }
                                        />
                                        <Typography
                                          variant="body1"
                                          marginLeft={1}
                                        >
                                          {display.status_light_name}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              )}
                            </>
                          );
                        }
                      })}
                    </Box>
                  </CardContent>
                </Card>
              ) : (
                node.nodetype_input_and_display.length != 0 &&
                node.nodetype_input_and_display.map((display, index2) => {
                  if (display.is_control) {
                    const [control, setControl] = useState<string>("");
                    const handleChange = (event: any) => {
                      setControl(event.target.value);
                    };
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{
                          // backgroundColor: '#E5E5E5',
                          borderRadius: "0.6rem",
                          marginBottom: 2,
                        }}
                      >
                        <Card
                          className="shadow-lg rounded-lg"
                          key={`control-${index}`}
                          sx={{
                            textAlign: "center",
                            borderRadius: "12px",
                            backgroundColor: "rgba(255, 255, 255, 1)",
                            boxShadow: "none",
                          }}
                        >
                          <div className="bg-gray-100 p-4 border-b border-gray-200">
                            <Typography variant="h6" component="div">
                              การควบคุม :{" "}
                              {/* {settingNodes?.setting_node_data_name} */}
                              {node?.setting_node_data_name}
                            </Typography>
                          </div>

                          <CardContent>
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {node?.nodetype_input_and_display.map(
                                (btn, index) =>
                                  display.is_btn ? (
                                    <Button
                                      key={index}
                                      onClick={() =>
                                        isTextSubmit(
                                          btn.btn_value,
                                          btn.btn_name,
                                          node.setting_node_id,
                                          node
                                        )
                                      }
                                      variant="contained"
                                      style={{
                                        backgroundColor: display.btn_color,
                                        marginRight: 10,
                                      }}
                                      className="bg-blue-500"
                                    >
                                      {display.btn_name}
                                    </Button>
                                  ) : display.is_text_input && index === 0 ? (
                                    <Box
                                      key={`input-${index}`}
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      gap={2}
                                      sx={{ width: "100%" }}
                                    >
                                      <TextField
                                        fullWidth
                                        label={display.text_input_name}
                                        value={control}
                                        onChange={handleChange}
                                        sx={{ flexGrow: 1 }}
                                      />
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        className="bg-blue-500"
                                        sx={{
                                          width: "auto",
                                          height: "55px",
                                          whiteSpace: "nowrap",
                                        }}
                                        onClick={() =>
                                          isTextSubmit(
                                            control,
                                            display.text_input_name,
                                            node?.setting_node_id,
                                            node
                                          )
                                        }
                                      >
                                        ดำเนินการ
                                      </Button>
                                    </Box>
                                  ) : null
                              )}
                            </Box>
                          </CardContent>

                          <CardActions className="p-4 content-center" />
                        </Card>
                      </Grid>
                    );
                  }
                  if (
                    display.is_monitor &&
                    (display.is_img || display.is_graph)
                  ) {
                    const imagepath = checkImageExists(
                      `/ProjectImages/${node?.nodetype_input_and_display.map(
                        (item) => item.img_group_name
                      )}/${parseInt(handleArr(
                        node!,
                        socketData[floodgateId]?.[
                        node?.setting_node_data_key || ""
                        ] || "0"
                      ))}.png`
                    );

                    const [open, setOpen] = useState(false);
                    const handleOpen = () => setOpen(true);
                    const handleClose = () => setOpen(false);
                    return (
                      <Card
                        className="shadow-lg rounded-lg"
                        key={`control-${index}`}
                        sx={{
                          textAlign: "center",
                          borderRadius: "12px",
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          boxShadow: "none",
                        }}
                      >
                        <div className="bg-gray-100 p-4 border-b border-gray-200">
                          <Typography variant="h6" component="div">
                            {node.setting_node_data_name}
                          </Typography>
                        </div>

                        <CardContent>
                          <Box
                            display="flex"
                            flexWrap="wrap"
                            alignItems="center"
                            justifyContent="flex-start"
                          >
                            {display.is_monitor && (
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  // backgroundColor: '#FFF',
                                  borderRadius: "0.6rem",
                                }}
                              >
                                <StyledPaper>
                                  <Typography
                                    variant="h6"
                                    textAlign="left"
                                    gutterBottom
                                  >
                                    {node?.setting_node_data_name}
                                  </Typography>
                                  <Typography
                                    variant="h3"
                                    textAlign="center"
                                    gutterBottom
                                  >
                                    {handleArr(
                                      node!,
                                      socketData[floodgateId]?.[
                                      node?.setting_node_data_key || ""
                                      ] || "0"
                                    )}
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    textAlign="center"
                                    gutterBottom
                                  >
                                    {node?.setting_node_data_unit}
                                  </Typography>
                                </StyledPaper>
                              </Grid>
                            )}
                            {display.is_img && (
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  // backgroundColor: '#FFF',
                                  borderRadius: "0.6rem",
                                }}
                              >
                                <StyledPaper>
                                  <Typography
                                    variant="h6"
                                    textAlign="left"
                                    gutterBottom
                                  >
                                    รูปภาพกราฟฟิก :{" "}
                                    {node?.setting_node_data_name}
                                  </Typography>
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Box
                                      component="img"
                                      src={imagepath}
                                      alt="Image"
                                      sx={{
                                        width: "50%",
                                        height: "auto",
                                        borderRadius: "8px",
                                        boxShadow: 3,
                                      }}
                                    />
                                  </Box>
                                </StyledPaper>
                              </Grid>
                            )}
                            {display.is_graph && (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    // backgroundColor: '#FFF',
                                    borderRadius: "0.6rem",
                                  }}
                                >
                                  <StyledPaper onClick={handleOpen}>
                                    <Typography
                                      variant="h6"
                                      textAlign="left"
                                      gutterBottom
                                    >
                                      กราฟแสดงค่า :{" "}
                                      {node?.setting_node_data_name}
                                    </Typography>
                                    <LineChartComponent
                                      socketData={socketData}
                                      settingNodes={node}
                                      setting_node_data_name={
                                        node.setting_node_data_name
                                      }
                                      height={200}
                                      graph_color={display.graph_color}
                                      initialData={initialData}
                                    />
                                  </StyledPaper>
                                </Grid>
                                <Modal
                                  open={open}
                                  onClose={handleClose}
                                  aria-labelledby="modal-title"
                                  aria-describedby="modal-description"
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: {
                                        xs: "90%", // หน้าจอเล็กสุด (มือถือ) ให้ใช้เต็มความกว้าง
                                        sm: "90%", // หน้าจอ sm (เล็ก) ให้ใช้ 90%
                                        md: "80%", // หน้าจอขนาดกลางให้ใช้ 80%
                                        lg: "70%", // หน้าจอขนาดใหญ่ให้ใช้ 70%
                                      },
                                      height: {
                                        xs: "60%", // หน้าจอ xs (มือถือ) ให้สูง 80%
                                        sm: "70%", // หน้าจอ sm ให้สูง 70%
                                        md: "90%", // หน้าจอขนาดกลางให้สูง 90%
                                        lg: "90%", // หน้าจอขนาดใหญ่ให้สูง 90%
                                      },
                                      backgroundColor: "white",
                                      borderRadius: "10px",
                                      boxShadow: 24,
                                      padding: 4,
                                      position: "relative",
                                      display: "flex", // ใช้ flexbox เพื่อจัด layout ภายใน
                                      flexDirection: "column", // จัดเรียง items แนวตั้ง
                                      overflow: "hidden", // ซ่อนเนื้อหาที่ล้น
                                    }}
                                  >
                                    <Typography
                                      id="modal-title"
                                      variant={isMobile ? "h6" : "h4"}
                                      gutterBottom
                                    >
                                      กราฟแสดงค่า :{" "}
                                      {node?.setting_node_data_name}
                                    </Typography>

                                    <Box
                                      sx={{
                                        flexGrow: 1, // ให้ LineChartComponent เติบโตเต็มที่ที่เหลืออยู่
                                        overflow: "auto", // ทำให้ Chart เลื่อนถ้าเกินขนาด
                                      }}
                                    >
                                      <LineChartComponent
                                        socketData={socketData}
                                        settingNodes={node}
                                        setting_node_data_name={
                                          node.setting_node_data_name
                                        }
                                        height={isMobile ? 200 : "100%"}
                                        graph_color={display.graph_color}
                                        initialData={initialData}
                                      />
                                    </Box>

                                    {/* ปุ่มปิด อยู่ที่มุมขวาบน */}
                                    <Button
                                      onClick={handleClose}
                                      variant="contained"
                                      sx={{
                                        position: "absolute",
                                        top: 16,
                                        right: 16,
                                      }}
                                    >
                                      ปิด
                                    </Button>
                                  </Box>
                                </Modal>
                              </>
                            )}
                          </Box>
                        </CardContent>
                      </Card>
                    );
                  }
                  if (display.is_monitor && display.is_status_light) {
                    console.log("Status Light : ", display.status_light_name);
                    console.log(display.status_light_value);
                    console.log(
                      handleArr(
                        node!,
                        socketData[floodgateId]?.[
                        node?.setting_node_data_key || ""
                        ] || "0"
                      )
                    );
                    return (
                      <>
                        {display.is_status_light && (
                          <Grid
                            item
                            sx={{
                              backgroundColor: "#FFF",
                              borderRadius: "0.6rem",
                              padding: 2,
                            }}
                          >
                            <Typography variant="h6" component="div">
                              {node.setting_node_data_name}
                            </Typography>
                            {node.nodetype_input_and_display.length != 0 &&
                              node.nodetype_input_and_display.map((display) => {
                                return (
                                  <Box
                                    display="flex"
                                    flexWrap="wrap"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                  >
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="flex-start"
                                      marginRight={2}
                                      marginBottom={2}
                                    >
                                      <CircleComponent
                                        color="black"
                                        headColor={
                                          (node.setting_node_data_type == "1" || node.setting_node_data_type != "0") ?
                                            (parseFloat(display.status_light_value) ===
                                              parseFloat(handleArr(
                                                node!,
                                                socketData[floodgateId]?.[
                                                node?.setting_node_data_key || ""
                                                ] || "0"))
                                              ? display.status_light_color
                                              : light_color)
                                            : (display.status_light_value ===
                                              handleArr(
                                                node!,
                                                socketData[floodgateId]?.[
                                                node?.setting_node_data_key || ""
                                                ] || "0")
                                              ? display.status_light_color
                                              : light_color)
                                        }
                                      />
                                      <Typography
                                        variant="body1"
                                        marginLeft={1}
                                      >
                                        {display.status_light_name}
                                      </Typography>
                                    </Box>
                                  </Box>
                                );
                              })}
                          </Grid>
                        )}
                      </>
                    );
                  }
                })
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NodeItem;
