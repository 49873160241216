import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import CardImage from "../CardImage";
import NodeItem from "./NodeItem";
import { useAppDispatch, useAppSelector } from "../../../../stores/store";
import {
  fetchMonitorDetail,
  monitorDetailSelector,
} from "../../../../stores/slices/MonitorDetailSlice";
// import { MonitorDetailRequest } from '../../../../models/monitor/MonitorDetailRequest.model';
import {
  NodetypeInputAndDisplay,
  SettingNode,
  SettingType,
  FloodgateResponse,
} from "../../../../models/monitor/MonitorDetail.model";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  colors,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { io } from "socket.io-client";
import config from "../../../../config";
import { eUserRole } from "../../../../services/Constants/enum/enum.model";
import { userRolesSelector } from "../../../../stores/slices/UserRolesSlice";
import {
  fetchTransationMonitor,
  transationMonitorSelector,
} from "../../../../stores/slices/FloodgateTransationMonitorSlice";
import { MonitorDetailRequest } from "../../../../models/monitor/MonitorDetailRequest.model";

const TransparentAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: "#EFEFEF",
  borderRadius: "8px",
  boxShadow: "none",
  marginBottom: theme.spacing(2),
}));

const MonitorDetail: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const {
    data: dataMonitorDetail,
    isLoading,
    isError,
    errorMessage,
  } = useAppSelector(monitorDetailSelector);
  const [socketData, setSocketData] = useState<{
    [key: string]: { [key: string]: string };
  }>({});
  const { data: initial_data, isLoading: init_data_load } = useAppSelector(
    transationMonitorSelector
  );

  const userRole = useAppSelector(userRolesSelector);
  const hasRole = (roles: eUserRole[]): boolean => {
    if (!userRole.data) return false;
    if (Array.isArray(userRole.data)) {
      return userRole.data.some((role) =>
        roles.includes(role.role_account_id as eUserRole)
      );
    }
    return roles.includes(userRole.data.role_account_id as eUserRole);
  };
  useEffect(() => {
    const requestData: MonitorDetailRequest = {
      floodgate_id: id || "", // ตรวจสอบว่ามี id หรือไม่
    };
    dispatch(fetchMonitorDetail(requestData));
    dispatch(fetchTransationMonitor({ floodgate_id: id || "" }));

    const socket = io(config.ws.ws_floodgate_guest_url);
    socket.on("data", (data) => {
      const newData = data.reduce((acc: any, item: any) => {
        acc[item.floodgate_id] = item.floodgate_transaction_details;
        acc[item.floodgate_id].floodgate_transaction_createdate =
          item.floodgate_transaction_createdate;
        return acc;
      }, {});

      setSocketData(newData);
    });
    return () => {
      socket.disconnect();
    };
  }, [dispatch, id]);

  if (isLoading || init_data_load) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error: {errorMessage}</div>;
  }
  const filteredSettingNodes = dataMonitorDetail?.response.setting_nodes.filter(
    (node) =>
      hasRole([eUserRole.Operator])
        ? !node.nodetype_input_and_display.some((display) => display.is_control) // กรอง setting_node ที่ไม่มี is_control สำหรับ Operator
        : true // ถ้าไม่ใช่ Operator ให้แสดงทั้งหมด
  );

  const mapSettingNodes = (settingNodes: any) => {
    const mappedData: { [key: string]: SettingType } = {};

    settingNodes.forEach((node: any) => {
      if (!node?.setting_node_type_communication) {
        const settingTypeId = node.setting_type_id;

        const settingTypeGroup =
          node.setting_type?.setting_type_group || "Unknown Group";
        const settingTypeName =
          node.setting_type?.setting_type_name || "Unknown Name";

        if (!mappedData[settingTypeId]) {
          mappedData[settingTypeId] = {
            setting_type_id: settingTypeId.toString(),
            setting_type_group: settingTypeGroup,
            setting_type_name: settingTypeName,
            setting_nodes: [],
          };
        }

        mappedData[settingTypeId].setting_nodes.push({
          setting_node_id: node.setting_node_id,
          setting_type_id: node.setting_type_id,
          setting_type: node.setting_type,
          floodgate_id: node.floodgate_id,
          setting_node_data_display_main: node.setting_node_data_display_main,
          setting_node_data_key: node.setting_node_data_key,
          setting_node_data_name: node.setting_node_data_name,
          setting_node_data_min: node.setting_node_data_min,
          setting_node_data_max: node.setting_node_data_max,
          setting_node_data_unit: node.setting_node_data_unit,
          setting_node_type_communication: node.setting_node_type_communication,
          setting_node_data_type: node.setting_node_data_type,
          setting_node_sequence: node.setting_node_sequence,
          setting_node_discription: node.setting_node_discription,
          nodetype_input_and_display: node.nodetype_input_and_display.map(
            (display: NodetypeInputAndDisplay) => ({
              type_input_and_display_id: display.type_input_and_display_id,
              setting_node_id: display.setting_node_id,
              is_control: display.is_control,
              is_btn: display.is_btn,
              btn_value: display.btn_value,
              btn_name: display.btn_name,
              btn_color: display.btn_color,
              is_text_input: display.is_text_input,
              text_input_name: display.text_input_name,
              is_monitor: display.is_monitor,
              is_status_light: display.is_status_light,
              status_light_value: display.status_light_value,
              status_light_name: display.status_light_name,
              status_light_color: display.status_light_color,
              is_graph: display.is_graph,
              graph_color: display.graph_color,
              is_img: display.is_img,
              img_group_name: display.img_group_name,
            })
          ),
        });
      }
    });

    return Object.values(mappedData);
  };

  const mappedSettingNodes = filteredSettingNodes
    ? mapSettingNodes(filteredSettingNodes)
    : [];

  return (
    <Box sx={{ padding: 2 }}>
      <TransparentAccordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            {dataMonitorDetail?.response.floodgate_name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {/* ตรวจสอบว่ามีข้อมูลก่อนการ map */}
            {mappedSettingNodes &&
              mappedSettingNodes.map((setting_type_group, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  key={setting_type_group.setting_type_id}
                >
                  <Grid
                    item
                    sx={{
                      backgroundColor: "rgb(0 124 255 / 9%)",
                      padding: 2,
                      borderRadius: "0.6rem",
                    }}
                  >
                    {setting_type_group.setting_type_name}
                    <Box
                      component="hr"
                      sx={{
                        border: "1px solid #000", // สีและขนาดของเส้น
                        width: "100%", // ความกว้าง
                        margin: "20px 0", // การตั้งค่าระยะห่าง
                      }}
                    />
                    <NodeItem
                      path="FlapGateWeirCurved1"
                      imagename="0"
                      settingNodes={setting_type_group.setting_nodes}
                      socketData={socketData}
                      initialData={initial_data}
                    />
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
      </TransparentAccordion>
    </Box>
  );
};

export default MonitorDetail;
